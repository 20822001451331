import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurProducts from "./pages/OurProducts";
import GoldJewelleries from "./pages/GoldJewelleries"; // Import the new GoldJewelleries component
import SilverJewelleries from "./pages/SilverJewelleries";
import PreciousStones from "./pages/PreciousStones";
import SemiPreciousStones from "./pages/SemiPreciousStones";
import Reviews from "./pages/Reviews";
import ContactUs from "./pages/ContactUs";
import Navbar from "./components/Navbar";


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-products" element={<OurProducts />} />
        <Route path="/gold-jewellery" element={<GoldJewelleries />} />
        <Route path="/silver-jewellery" element={<SilverJewelleries />} />
        <Route path="/precious-stones" element={<PreciousStones />} />
        <Route path="/semi-precious-stones" element={<SemiPreciousStones />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>

    </Router>
  );
}

export default App;
