import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo-removebg-preview.png';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setShowNavbar(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className={`navbar ${showNavbar ? 'show' : 'hide'}`}>
            {/* Top section */}
            <div className="top-section">
                <div className="shop-name">
                    <h2>DARA</h2>
                    <h4>GEMS (PVT) Ltd.</h4>
                    <p>Est. 1981</p>
                </div>
                <div className="logo-container">
                    <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="icons">
                    <a href="tel:0777939655">
                        <FaPhoneAlt className="icon" />
                    </a>
                    <a href="https://maps.app.goo.gl/UJkAkjctgpCwqs7z8" target="_blank" rel="noopener noreferrer">
                        <FaMapMarkerAlt className="icon" />
                    </a>
                    <a href="mailto:daragems@yahoo.com">
                        <FaEnvelope className="icon" />
                    </a>
                </div>
            </div>

            {/* Bottom section */}
            <div className="bottom-section">
                {/* Desktop Navigation Links */}
                <ul className="nav-links">
                    <li className="nav-item">
                        <NavLink to="/" className="nav-link" activeClassName="active">Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/our-products" className="nav-link" activeClassName="active">Jewelleries & Stones</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/reviews" className="nav-link" activeClassName="active">Reviews</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/about-us" className="nav-link" activeClassName="active">About Us</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/contact-us" className="nav-link" activeClassName="active">Contact Us</NavLink>
                    </li>
                </ul>

                {/* Mobile Menu Button */}
                <button className="mobile-menu-button" onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? "✖" : "☰"}
                </button>
            </div>

            {/* Mobile Menu Overlay */}
            {isMobileMenuOpen && (
                <div className="mobile-menu">
                    <button className="close-menu" onClick={toggleMobileMenu}></button>
                    <ul className="mobile-nav-links">
                        <li>
                            <NavLink to="/" className="nav-link" onClick={toggleMobileMenu}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/our-products" className="nav-link" onClick={toggleMobileMenu}>Jewelleries & Stones</NavLink>
                        </li>
                        <li>
                            <NavLink to="/reviews" className="nav-link" onClick={toggleMobileMenu}>Reviews</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about-us" className="nav-link" onClick={toggleMobileMenu}>About Us</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact-us" className="nav-link" onClick={toggleMobileMenu}>Contact Us</NavLink>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Navbar;