import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import EmailJS
import "./ContactUs.css"; // Import the CSS for styling
import Footer from "../components/Footer";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const [statusMessage, setStatusMessage] = useState("");

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Replace these with your EmailJS credentials
        const serviceID = "service_togg744";
        const templateID = "template_quknup2";
        const userID = "m3ES637QNlfNArKuj";

        emailjs
            .send(serviceID, templateID, formData, userID)
            .then((response) => {
                console.log("SUCCESS!", response.status, response.text);
                setStatusMessage("Your message has been sent successfully!");
                setFormData({ name: "", email: "", phone: "", message: "" }); // Clear form
            })
            .catch((error) => {
                console.error("FAILED...", error);
                setStatusMessage("Failed to send your message. Please try again later.");
            });
    };

    return (
        <div className="contact-us-container">
            {/* Form Section */}
            <div className="contact-us-form-section">
                <form className="contact-us-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email *</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Enter your phone number"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Enter your message"
                            value={formData.message}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <button type="submit" className="form-submit-button">SEND</button>
                </form>
                {statusMessage && <p className="status-message">{statusMessage}</p>}
            </div>

            {/* Contact Details Section */}
            <div className="contact-details-section">
                <h3>Main Office</h3>
                <p>39, Galle Road, Kalawilawatta, Moragalla, Aluthgama 12080</p>
                <p>Phone: +94 777939655</p>
                <p>Tel: +94 342279435</p>
                <p>Email: daragems@yahoo.com</p>
            </div>

            {/* Location Map Section */}
            <div className="location-map-section">
                <h2 className="map-heading">Our Location in Sri Lanka</h2>
                <iframe
                    src="https://maps.google.com/maps?q=No:%20730%20Galle%20Road,%20Colombo-03,%20Sri%20Lanka&output=embed"
                    width="100%"
                    height="400"
                    className="location-map"
                    allowFullScreen
                    loading="lazy"
                    title="Location Map"
                ></iframe>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;
