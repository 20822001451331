import React from 'react';
import './ProductModal.css'; // Import the CSS file

const ProductModal = ({ productImages, productNames, productDescription, productName, closeModal }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-container">
                {/* Close button */}
                <button onClick={closeModal} className="close-btn">X</button>

                {/* Product Name at the Top */}
                <h2 className="product-name">{productName}</h2>

                {/* Our Popular Products heading */}
                <h3 className="popular-products">Our Popular Products</h3>

                {/* Image Gallery with Names */}
                <div className="image-gallery">
                    {productImages.map((image, index) => (
                        <div key={index} className="image-item">
                            <img src={image} alt={`Design ${index + 1}`} />
                            <p className="image-name">{productNames[index]}</p> {/* Display name below image */}
                        </div>
                    ))}
                </div>

                {/* Product Description */}
                <p className="product-description">{productDescription}</p>
            </div>
        </div>
    );
};

export default ProductModal;
