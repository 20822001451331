import React, { useState } from "react";
import "./PreciousStones.css";
import yellowSapphireImage from "../assets/semi precious stones/Untitled design/8.png"; // Replace with your actual image path
import greenSapphireImage from "../assets/semi precious stones/Untitled design/4.png"; // Replace with your actual image path
import blueSapphireImage from "../assets/semi precious stones/Untitled design/5.png"; // Replace with your actual image path
import orangeSapphireImage from "../assets/semi precious stones/Untitled design/6.png"; // Replace with your actual image path
import rubyStonesImage from "../assets/semi precious stones/images (4).jpeg"; // Add your actual image path
import topazStonesImage from "../assets/semi precious stones/images (1).jpeg"; // Add your actual image path
import amethystStonesImage from "../assets/semi precious stones/images (3).jpeg"; // Add your actual image path

import goldJewelleriesImage from "../assets/preciousstones/gold-jewelries.jpeg"; // Add your actual image path
import silverJewelleriesImage from "../assets/preciousstones/80393529_2731643716897237_8539846507300388864_n.jpg"; // Add your actual image path
import semiPreciousStonesImage from "../assets/preciousstones/IMG_5342_1200x800.webp"; // Add your actual image path

import Footer from "../components/Footer";

const SemiPreciousStones = () => {
    const [selectedColor, setSelectedColor] = useState("blue");

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    return (
        <div className="precious-stones-container">
            <section className="sapphire-colors">
                <div className="sapphire-details">
                    <div className="sapphire-text">
                        <h1>Stones</h1>
                        <h2>Moon Stones</h2>
                        <p>
                            Moonstones are special because of their enchanting, milky iridescence, known as adularescence, and their deep connection to mysticism and femininity. Sri Lanka, often called the "Gem Island," is renowned for producing some of the world's finest moonstones, prized for their captivating glow and ethereal beauty. The country's rich geological diversity and centuries-old mining traditions make it a global leader in high-quality moonstone production, celebrated for their unique charm and spiritual significance.
                        </p>
                    </div>
                    <div className="sapphire-image-container">
                        <img
                            src={
                                selectedColor === "blue"
                                    ? yellowSapphireImage
                                    : selectedColor === "white"
                                        ? greenSapphireImage
                                        : selectedColor === "indian"
                                            ? blueSapphireImage
                                            : selectedColor === "rainbow"
                                                ? orangeSapphireImage
                                                : yellowSapphireImage // Add a fallback value here
                            }
                            alt={selectedColor + " Moonstones"}
                            className="sapphire-image"
                        />
                        <div className="color-options">
                            <button
                                className={selectedColor === "blue" ? "active" : ""}
                                onClick={() => handleColorClick("blue")}
                            >
                                Blue
                            </button>
                            <button
                                className={selectedColor === "white" ? "active" : ""}
                                onClick={() => handleColorClick("white")}
                            >
                                White
                            </button>
                            <button
                                className={selectedColor === "indian" ? "active" : ""}
                                onClick={() => handleColorClick("indian")}
                            >
                                Indian
                            </button>
                            <button
                                className={selectedColor === "rainbow" ? "active" : ""}
                                onClick={() => handleColorClick("rainbow")}
                            >
                                Rainbow
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <div className="stone-cards-section">

                <div className="stone-cards-container">
                    <div className="stone-cards-card">
                        <img src={rubyStonesImage} alt="Silver Jewelleries" />
                        <h3>Citrine</h3>
                    </div>
                    <div className="stone-cards-card">
                        <img src={topazStonesImage} alt="Precious Stones" />
                        <h3>Garnet</h3>
                    </div>
                    <div className="stone-cards-card">
                        <img src={amethystStonesImage} alt="Semi Precious Stones" />
                        <h3>Quartz</h3>
                    </div>
                </div>
            </div>
            <div className="other-products-section">
                <h2>Other Products</h2>
                <div className="other-products-container">
                    <a href="/gold-jewellery" className="other-product-card">
                        <img src={goldJewelleriesImage} alt="Silver Jewelleries" />
                        <h3>Gold Jewelleries</h3>
                    </a>
                    <a href="/silver-jewellery" className="other-product-card">
                        <img src={silverJewelleriesImage} alt="Precious Stones" />
                        <h3>Silver Jewelleries</h3>
                    </a>
                    <a href="/precious-stones" className="other-product-card">
                        <img src={semiPreciousStonesImage} alt="Semi Precious Stones" />
                        <h3>Precious Stones</h3>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SemiPreciousStones;
