import React, { useState, useEffect } from "react";
import { db } from "./firebase"; // Firebase configuration
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import reviewImage from "../assets/Reviews/332491618-512565987719500-364293.jpg"; // Main image
import image1 from "../assets/Reviews/Reviews Images/328958728_119255784402884_8092654546083570996_n.webp";
import image2 from "../assets/Reviews/Reviews Images/329308981_896796108030418_1237783788672651321_n.webp";
import image3 from "../assets/Reviews/Reviews Images/332410644_220893283662295_165552602750060654_n.webp";
import image4 from "../assets/Reviews/Reviews Images/334266610_227262859691110_6493288739804661693_n.webp";
import image5 from "../assets/Reviews/Reviews Images/334320243_509795931232030_5892223043956404549_n.webp";
import image6 from "../assets/Reviews/Reviews Images/321543311_598604438643883_8055534640069025289_n.jpg";
import image7 from "../assets/Reviews/Reviews Images/321708573_127154446881014_8819421482320636464_n.jpg";
import image8 from "../assets/Reviews/Reviews Images/327602008_1225057631694398_6657205941100930103_n.jpg";
import image9 from "../assets/Reviews/Reviews Images/322657315_140178345539148_2420194934465666291_n.jpg";
import image10 from "../assets/Reviews/Reviews Images/325469534_738264114308209_8131466199111407628_n.jpg";
import Footer from "../components/Footer";
import ReactStars from "react-rating-stars-component";
import "./Reviews.css";

const Reviews = () => {
    const [reviews, setReviews] = useState([]);
    const [formData, setFormData] = useState({
        rating: 0,
        title: "",
        review: "",
        name: "",
        email: "",
    });
    const [isLoading, setIsLoading] = useState(true); // Loader flag for fetching data

    // Fetch reviews from Firestore
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "reviews"), (snapshot) => {
            setReviews(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
            setIsLoading(false);
        });
        return () => unsubscribe();
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Add review to Firestore
            await addDoc(collection(db, "reviews"), {
                ...formData,
                date: new Date().toISOString(),
            });
            alert("Review submitted successfully!");
            // Clear form after submission
            setFormData({ rating: 0, title: "", review: "", name: "", email: "" });
        } catch (error) {
            console.error("Error adding review: ", error.message);
            alert("Failed to submit the review. Please try again.");
        }
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle star rating changes
    const handleRatingChange = (newRating) => {
        setFormData({ ...formData, rating: newRating });
    };

    return (
        <div className="rev-container">
            {/* Top Section */}
            <div className="rev-image-section">
                <img src={reviewImage} alt="Our Reviews" className="rev-image" />
                <h1 className="rev-heading">Our Customer Reviews</h1>
            </div>

            {/* Reviews Section */}
            <div className="rev-section">
                {/* Left Column: Form */}
                <div className="rev-form">
                    <h2>Submit Your Review</h2>
                    <form onSubmit={handleSubmit}>
                        <label>Your Overall Rating</label>
                        <ReactStars
                            count={5}
                            size={30}
                            isHalf={true}
                            value={formData.rating}
                            onChange={handleRatingChange}
                        />
                        <label>Title of Your Review</label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            placeholder="Enter title"
                            required
                        />
                        <label>Your Review</label>
                        <textarea
                            name="review"
                            value={formData.review}
                            onChange={handleChange}
                            placeholder="Write your review"
                            required
                        ></textarea>
                        <label>Your Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                        />
                        <label>Your Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            required
                        />
                        <button type="submit" disabled={isLoading}>Submit Your Review</button>
                    </form>
                </div>

                {/* Right Column: Reviews */}
                <div className="rev-list">
                    <h2>Customer Reviews</h2>
                    {isLoading ? (
                        <p>Loading reviews...</p>
                    ) : reviews.length > 0 ? (
                        reviews.map((review) => (
                            <div key={review.id} className="rev-item">
                                <h3>{review.title}</h3>
                                <ReactStars count={5} size={20} isHalf={true} value={review.rating} edit={false} />
                                <p>{review.review}</p>
                                <p>
                                    <strong>- {review.name}</strong>
                                </p>
                                <p className="review-date">
                                    {new Date(review.date).toLocaleDateString(undefined, {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    })}
                                </p>
                            </div>
                        ))
                    ) : (
                        <p>No reviews yet. Be the first to submit a review!</p>
                    )}
                </div>
            </div>

            {/* Bottom Section: Images */}
            <div className="rev-images">
                <img src={image1} alt="Review 1" />
                <img src={image2} alt="Review 2" />
                <img src={image3} alt="Review 3" />
                <img src={image4} alt="Review 4" />
                <img src={image5} alt="Review 5" />
                <img src={image6} alt="Review 6" />
                <img src={image7} alt="Review 7" />
                <img src={image8} alt="Review 8" />
                <img src={image9} alt="Review 9" />
                <img src={image10} alt="Review 10" />
            </div>
            <Footer />
        </div>
    );
};

export default Reviews;