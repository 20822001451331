import React from "react";
import { FaFacebookF, FaInstagram, FaYoutube, FaPinterest, FaEnvelope } from "react-icons/fa";
import { FaCcAmex, FaCcDiscover, FaCcMastercard, FaCcPaypal, FaCcVisa } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-overlay">
                <div className="footer-content">
                    {/* Contact Section */}
                    <div className="footer-section contact">
                        <h4>Contact</h4>
                        <p>
                            Dara Gems & Jewellery (Pvt) Ltd</p>
                        <p>39, Galle Road, Kalawilawatta, Moragalla,Aluthgama 12080</p>
                        <p><strong>Email:</strong> daragems@yahoo.com </p>
                        <p><strong>Phone:</strong> +94 777939655</p>
                        <p><strong>Hours:</strong></p>
                        <p>Mon - Sun: 9AM - 7PM</p>
                        <p>Fri: 9AM - 11.45AM</p>
                        <p>2.15PM - 7PM</p>
                    </div>

                    {/* Shop Section */}
                    <div className="footer-section shop">
                        <h4>Shop</h4>
                        <ul>
                            <li>
                                <a href="/gold-jewelleries" target="_blank" rel="noopener noreferrer">
                                    Gold Jewelleries
                                </a>
                            </li>
                            <li>
                                <a href="/silver-jewelleries" target="_blank" rel="noopener noreferrer">
                                    Silver Jewelleries
                                </a>
                            </li>
                            <li>
                                <a href="/precious-stones" target="_blank" rel="noopener noreferrer">
                                    Precious Stones
                                </a>
                            </li>
                            <li>
                                <a href="/semi-precious-stones" target="_blank" rel="noopener noreferrer">
                                    Semi Precious Stones
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Services Section */}
                    <div className="footer-section services">
                        <h4>Services</h4>
                        <ul>
                            <li>Custom Design</li>
                            <li>Ring Sizing</li>
                            <li>Stone Replacement</li>
                            <li>Cleaning and Polishing</li>
                            <li>Jewelry Plating</li>
                            <li>Jewelry Repair</li>

                        </ul>
                    </div>

                    {/* Signup Section */}
                    <div className="footer-section signup">
                        <h4>Sign Up and Save</h4>
                        <p>Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.</p>
                        <form>
                            <input type="email" placeholder="Enter your email" />
                            <button type="submit">
                                <FaEnvelope />
                            </button>
                        </form>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/share/2PCKGMRfX7ghbSe7/" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF />
                            </a>
                            <a href="https://www.instagram.com/dara_gems?utm_source=qr&igsh=Y3djMmE5bm04Y3Ew" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                                <FaYoutube />
                            </a>
                            <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer">
                                <FaPinterest />
                            </a>
                        </div>

                    </div>
                </div>

                {/* Footer Bottom Section */}
                <div className="footer-bottom">
                    <div className="payment-methods">
                        <FaCcAmex />
                        <FaCcDiscover />
                        <FaCcMastercard />
                        <FaCcPaypal />
                        <FaCcVisa />
                    </div>
                    <h2>YOUR SATISFACTION IS OUR PRIDE</h2>
                    <p>© 2024 Dara Gems & Jewellery (Pvt) Ltd</p>
                    <p>

                        <a href="https://wa.me/94764608225" target="_blank" rel="noopener noreferrer">
                            Developed By Ricky Fernando
                        </a>
                    </p>

                </div>
            </div>
        </footer>
    );
};

export default Footer;