import React, { useState } from "react";
import "./PreciousStones.css";
import yellowSapphireImage from "../assets/preciousstones/6.png"; // Replace with your actual image path
import greenSapphireImage from "../assets/preciousstones/5.png"; // Replace with your actual image path
import blueSapphireImage from "../assets/preciousstones/ILA4795_101.webp"; // Replace with your actual image path
import orangeSapphireImage from "../assets/preciousstones/4.png"; // Replace with your actual image path
import redSapphireImage from "../assets/preciousstones/2.png"; // Replace with your actual image path

import rubyStonesImage from "../assets/preciousstones/ruby.jpeg"; // Add your actual image path
import topazStonesImage from "../assets/preciousstones/topaz-stones-2100079918-bpjs9pfw.jpg"; // Add your actual image path
import amethystStonesImage from "../assets/preciousstones/images (3).jpeg"; // Add your actual image path

import goldJewelleriesImage from "../assets/preciousstones/gold-jewelries.jpeg"; // Add your actual image path
import silverJewelleriesImage from "../assets/preciousstones/80393529_2731643716897237_8539846507300388864_n.jpg"; // Add your actual image path
import semiPreciousStonesImage from "../assets/preciousstones/IMG_5342_1200x800.webp"; // Add your actual image path

import Footer from "../components/Footer";

const PreciousStones = () => {
    const [selectedColor, setSelectedColor] = useState("blue");

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    return (
        <div className="precious-stones-container">
            <section className="sapphire-colors">
                <div className="sapphire-details">
                    <div className="sapphire-text">
                        <h1>Stones</h1>
                        <h2>Sapphires</h2>
                        <p>
                            {selectedColor} Sapphires are special because of their stunning deep
                            {selectedColor} color, exceptional durability, and association with
                            royalty and wisdom. Sri Lanka, often called the "Gem Island," is famous
                            for producing some of the world's finest {selectedColor} sapphires,
                            known for their vivid hues and clarity. The country's unique geology
                            and centuries-old mining heritage make it a global leader in high-quality
                            sapphire production.
                        </p>
                    </div>
                    <div className="sapphire-image-container">
                        <img
                            src={
                                selectedColor === "yellow"
                                    ? yellowSapphireImage
                                    : selectedColor === "green"
                                        ? greenSapphireImage
                                        : selectedColor === "blue"
                                            ? blueSapphireImage
                                            : selectedColor === "orange"
                                                ? orangeSapphireImage
                                                : redSapphireImage
                            }
                            alt={selectedColor + " Sapphire"}
                            className="sapphire-image"
                        />
                        <div className="color-options">
                            <button
                                className={selectedColor === "yellow" ? "active" : ""}
                                onClick={() => handleColorClick("yellow")}
                            >
                                Yellow
                            </button>
                            <button
                                className={selectedColor === "green" ? "active" : ""}
                                onClick={() => handleColorClick("green")}
                            >
                                Green
                            </button>
                            <button
                                className={selectedColor === "blue" ? "active" : ""}
                                onClick={() => handleColorClick("blue")}
                            >
                                Blue
                            </button>
                            <button
                                className={selectedColor === "orange" ? "active" : ""}
                                onClick={() => handleColorClick("orange")}
                            >
                                Orange
                            </button>
                            <button
                                className={selectedColor === "red" ? "active" : ""}
                                onClick={() => handleColorClick("red")}
                            >
                                Red
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <div className="stone-cards-section">

                <div className="stone-cards-container">
                    <div className="stone-cards-card">
                        <img src={rubyStonesImage} alt="Silver Jewelleries" />
                        <h3>Ruby</h3>
                    </div>
                    <div className="stone-cards-card">
                        <img src={topazStonesImage} alt="Precious Stones" />
                        <h3>Topaz</h3>
                    </div>
                    <div className="stone-cards-card">
                        <img src={amethystStonesImage} alt="Semi Precious Stones" />
                        <h3>Amethyst</h3>
                    </div>
                </div>
            </div>
            <div className="other-products-section">
                <h2>Other Products</h2>
                <div className="other-products-container">
                    <a href="/gold-jewellery" className="other-product-card">
                        <img src={goldJewelleriesImage} alt="Silver Jewelleries" />
                        <h3>Gold Jewelleries</h3>
                    </a>
                    <a href="/silver-jewellery" className="other-product-card">
                        <img src={silverJewelleriesImage} alt="Precious Stones" />
                        <h3>Silver Jewelleries</h3>
                    </a>
                    <a href="/semi-precious-stones" className="other-product-card">
                        <img src={semiPreciousStonesImage} alt="Semi Precious Stones" />
                        <h3>Semi Precious Stones</h3>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PreciousStones;
