import React from "react";
import aboutVideo from "../assets/About/1221.mp4"; // Import the image file
import rightImage from "../assets/About/About history/1.png"; // Import the right-side image
import rightImage1 from "../assets/About/2.png";
import chairmanImage from "../assets/About/WhatsApp Image 2024-12-15 at 18.54.35.jpeg"; // Import the Chairman's image
import "./AboutUs.css"; // Import the CSS for styling
import Footer from "../components/Footer";

import { VscCircleSmall } from "react-icons/vsc";

const AboutUs = () => {
    return (
        <div className=".about-us-container">
            {/* Top Section with Image */}
            <div className="about-us-image-section">
                <video
                    src={aboutVideo}
                    className="about-us-video"
                    autoPlay
                    loop
                    muted
                ></video>

            </div>
            <div className="about-us-content">
                <h1>About Our Company</h1>
            </div>
            {/* Two Columns Section */}
            <div className="about-us-content-section">

                <div className="left-column">
                    <h2 className="left-column-heading">Our History</h2>
                    <p>
                        We began DARA on 8th January 1981. Our Managing Director is a B.Sc Chemistry graduate from University of Ceylon, Peradeniya and was a university lecturer, chief chemist of the National Paper Corporation. He also obtained his post graduate Degree at the University of Norway. He is also an author of many books and articles both in English & Sinhala. He is a lyrics writer of Sinhala songs. He is a well known social service worker and is a Justice of the Peace for the whole island. He dedicated his services for the betterment of the paper Industry of our motherland working at Valaichena & Ambalapitia paper mills. But unfortunately due to continuous political interference, corruptions and racist attacks on him, he forcibly had to leave the government sector. During this time his wife late Hajiyani Mrs. Zareena Farook's beloved father late Mr. Fatha Hajiyar showed him the way of the business world. Mr. Fatha Hajiyar through one of his friend Mr. Robert Silva of Moragalle found a suitable place for a business stall on Gem & Jewellery mainly targeting the tourist flowing in to the Moragalle, Aluthgama hotels.
                    </p>
                    <p>My sister's husband late Al Haj Mr. M.M.A. Wadood was an Arabic scholar who gave us a suitable name for the stall to be opened! 'DARA' according to him means 'Tourist' and also the light circle around the Moon.</p>
                    <p>With so many teething problems the business went on and had to face so much obstacles and attacks from the outsiders and even from our own relation. But we managed to sail the ship facing storm, rain, sun and moon shines!!</p>
                    <p>The rented small building along with the land was purchased in 2009 with the consent of the land lord. A bigger spacious three storied building was planned and the building was in progress halfway, when the anti muslim racist led by the 'Bodhu Bala Sena' directed by Gnanasara thero and his mob broke, smashed and looted our shop in 2014. The business activities were hindered for nearly 06 months. Later at the end of 2014 the new building was opened for the client with much modernized showroom and trending articles.</p>
                    <p>Now we are passing the 42nd milestone of the business with tremendous good reviews and remarks about our services from the clients.</p>
                </div>
                <div className="right-column">
                    <img src={rightImage} alt="About Us Right" className="right-column-image-1" />

                </div>
            </div>

            {/* Chairman's Message Section */}
            <div className="chairman-message-section">
                <h2 className="chairman-message-heading">Chairman’s Message</h2>
            </div>

            {/* Chairman's Image and Message Section */}
            <div className="chairman-details-section">
                <div className="chairman-left-column">
                    <img src={chairmanImage} alt="Chairman" className="chairman-image" />
                </div>
                <div className="chairman-right-column">
                    <p>
                        Its with much pleasure I forward this message to all our customers, well wishers, and readers of our site DARA GEMS.
                        We have had much  pleasant memory in serving our customers from various parts of the Globe for the past four decades. Looking back at the performance of our company, we are much satisfied in this trade, and are happy that we have contributed valuable foreign exchange earnings to the National Economy, though in a small way ,with perfect patriotism.
                        our journey in this context was not a road filled with the blooming roses , but we have survived , thank God!! The 88/89 ,Insurrection,  ethnic war in the north for nearly thirty years, tsunami tragedy, anti Muslim communal riots from time to time, and the recent corona pandemic were the worst obstacles we faced during the past . We are proud to state that, our company has enjoyed our buissnes  turnover  and shared the earnings among the staff members reasonabley, and without removing any individual from service. Yes its an unbelievable task, and a great vision as in an ideal just society, which we believe In. This Is we have maintained even in very difficult periods we faced in tourism.
                        we also take this opportunity to thank all our new and regular costormers who gave us their wholehearted patrange.
                        Our Company is well known internationally in all most every country who admire and love to visit sri lanka , A Paradise like No other!
                        Now under my chairmanship, and a board of directors comprising of my two sons and  daughter. The routine activites are headed by my younger son Mr. Shazny Farook, as the Managing Director and a smooth operation headed by an experienced Manager, Assistant Manager and a cooperative staff members, and craftsmen.
                        Finally, I am pleased to assure our company’s unique service to all our costomers who flow in, throughout, as in the past, with much excellence,
                        Let me wind up this short message , by saying that we are always in your service, for a much pleasing and loveable life, for local customers and all nationalities, of the countries, beyond the ocean.
                        “YOUR SATISFACTION IS OUR PRIDE”_ its always our company’s motto.
                    </p>
                    <div className="chairman-signature">
                        <h1>A.S.M. Farook</h1>
                        <p>B.Sc, Chemistry special (Sri Lanka)</p>
                        <p>Post Graduate Degree in Pulp & Paper Technology(Norway)</p>
                    </div>

                </div>
            </div>
            <div className="about-us-team-section">
                <div className="team-left-column">
                    <h2 className="team-left-column-heading">OUR TEAM</h2>
                    <p>
                        Our Team is led by Chairman Al. Haj Mr.A.S.M Farook B.Sc Chemistry(University Of Ceylon, Peradeniya), Post Graduate Degree in Pulp, Paper and Wood Technology (University Of Norway), Justice of the Peace (Whole Island) with more than 40 years of experience in Gem & Jewellery field. To his credit he is an author of many books, articles in various fields and a sinhala songs lyrics writer too.
                    </p>
                    <p><VscCircleSmall /> <strong>Mr. M.F.M. SHAZNY</strong>, Managing Director with over 15 years of experience. He holds a <strong>Diploma in Marketing</strong> and possesses multilingual skills to communicate with customers worldwide. He is responsible for ensuring the smooth running of the establishment and maintaining excellent public relations.</p>

                    <p><VscCircleSmall /> <strong>Mr. M.S.M. SAFRAN</strong>, Manager with more than 10 years of experience in all aspects of the business. He manages the daily operations, customer relationships, and supplier interactions to ensure everything runs smoothly in the outlet.</p>

                    <p><VscCircleSmall /> <strong>Mr. M.N.M. NISHATH</strong>, Assistant Manager with more than 8 years of experience. He handles the financial control and accounting for the establishment, ensuring proper management of financial resources.</p>

                    <p><VscCircleSmall /> <strong>Mr. M.B.M. SAFHAN</strong>, Sales Executive with over 5 years of experience. He is responsible for sales, supply management, and maintenance of the shop, ensuring that the products are always available and in good condition.</p>

                    <p><VscCircleSmall /> <strong>Mr. UMAR FAROOK</strong>, Part-time Sales Executive with more than 2 years of experience. He is currently an undergraduate in Engineering at Curtin University and contributes to the sales team while pursuing his studies.</p>

                    <p><VscCircleSmall /> <strong>Mr. RASHID AHAMED</strong>, Sales & IT Assistant with 1 year of experience. He manages stock and assists in sales, while also handling the IT aspects of the business to ensure smooth operations.</p>

                    <p><VscCircleSmall /> <strong>Mr. M.M.M. MAFAD</strong>, Trainee Sales Assistant. A new addition to the team, he is being trained to assist in sales and contribute to the success of the business.</p>

                    <p><VscCircleSmall /> In addition, there are trainees who work alongside our team to provide excellent service to our clients and the general public.</p>

                    <h2 className="team-left-column-heading">Our Directors</h2>
                    <p>Mr. shiraz Farook Production Director in the Garment manufacturing trade, for more than 20years.</p>
                    <p>Mrs.F.Zayana Farook an educated lady with good knowledge in our Gem & Jewellery trade and experience in our Company activities.</p>
                    <h2 className="team-left-column-heading">Our Services</h2>
                    <p>Our Jewellery and any orders of our clients are made in our workshop equipped with all modern tools, machines along with a gem cutting and polishing machine with a well trained team of Goldsmiths and gem cutters, to give a perfect service to all our customers.</p>
                </div>
                <div className="team-right-column">
                    <img src={rightImage1} alt="About Us Right" className="team-right-column-image-1" />

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;
