
import React, { useState } from "react";


import silverPendantsImage from "../assets/Silver Jeweleries/images.jpeg";
import silverNecklacesImage from "../assets/Silver Jeweleries/80285193_2731268860268056_382139243373264896_n.jpg";
import silverEarringsImage from "../assets/Silver Jeweleries/Ana-Luisa-Jewelry-Earrings-Drop-Earrings-Silver-Earrings-Milena-Rhodium.webp";
import silverBraceletImage from "../assets/Silver Jeweleries/66bd1b666eee4431d37d7208-coopserbil-pure-silver-bracelet-sterling.jpg";
import silverBrauchesImage from "../assets/Silver Jeweleries/39638283_1920568241338126_2536193418242031616_n.jpg";
import silverCufflinksImage from "../assets/Silver Jeweleries/1184-Sterling-Silver-Trinity-Knot-Cufflinks.jpg";
import silverTiepinsImage from "../assets/Silver Jeweleries/Tie-Pin.jpg";
import silverChainsImage from "../assets/Silver Jeweleries/-original-imahyjffghhwbsnh.webp";


import silverPendantImage1 from "../assets/Silver Jeweleries/518whSQoumL._AC_UF894,1000_QL80_.jpg";
import silverPendantImage2 from "../assets/Silver Jeweleries/JS-5-218-scaled_2048x.webp";
import silverNecklaceImage1 from "../assets/Silver Jeweleries/images (1).jpeg";
import silverNecklaceImage2 from "../assets/Silver Jeweleries/51flig+C6jL._AC_UY1000_.jpg";
import silverNecklaceImage3 from "../assets/Silver Jeweleries/79510336_2731257900269152_518820014858436608_n.jpg";
import silverEarringImage1 from "../assets/Silver Jeweleries/2020-L2-CK5-42120225-07-1.webp";
import silverEarringImage2 from "../assets/Silver Jeweleries/151062735--1--1610970852.jpeg";

import goldJewelleriesImage from "../assets/Silver Jeweleries/gold-jewelries.jpeg";
import preciousStonesImage from "../assets/Silver Jeweleries/istockphoto-1067431158-612x612.jpg";
import semiPreciousStonesImage from "../assets/Silver Jeweleries/IMG_5342_1200x800.webp";

import "./GoldJewelleries.css";
import "./SilverJewelleries.css";
import ProductModal from "./ProductModal";
import Footer from "../components/Footer";

const SilverJewelleries = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProduct, setModalProduct] = useState({
        images: [],
        names: [],
        description: '',
        productName: '', // New state for product name
    });

    const openModal = (productName) => {
        let productImages = [];
        let productNames = [];
        let productDescription = '';

        switch (productName) {
            case 'Silver Pendant':
                productImages = [silverPendantImage1, silverPendantImage2];

                productDescription = 'Our collection of silver pendants features timeless designs, perfect for every occasion.';
                break;

            case 'Silver Necklace':
                productImages = [silverNecklaceImage1, silverNecklaceImage2, silverNecklaceImage3];

                productDescription = 'Silver necklaces that add a touch of elegance and sophistication to any outfit.';
                break;

            case 'Silver Earrings':
                productImages = [silverEarringImage1, silverEarringImage2];

                productDescription = 'A stunning collection of silver earrings that suit every style and occasion.';
                break;



            default:
                break;
        }

        setModalProduct({
            images: productImages,
            names: productNames,
            description: productDescription,
            productName: productName, // Pass the product name
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    return (
        <div className="gold-jewelleries-container">


            <div className="new-gold-items-section">
                <h2>Silver Items</h2>
                <div className="new-image-cards-container">


                    {/* Silver Pendants Card */}
                    <div className="img-card">
                        <img src={silverPendantsImage} alt="Silver Pendants" />
                        <h3>Pendants</h3>
                        <p>
                            Silver pendants that add elegance and sophistication to any outfit.
                        </p>
                        <button className="view-product-btn" onClick={() => openModal('Silver Pendant')}>
                            View Product
                        </button>
                    </div>

                    {/* Silver Necklaces Card */}
                    <div className="img-card">
                        <img src={silverNecklacesImage} alt="Silver Necklaces" />
                        <h3>Necklaces</h3>
                        <p>
                            Handcrafted silver necklaces perfect for gifting or as a personal accessory.
                        </p>
                        <button className="view-product-btn" onClick={() => openModal('Silver Necklace')}>
                            View Product
                        </button>
                    </div>

                    {/* Silver Earrings Card */}
                    <div className="img-card">
                        <img src={silverEarringsImage} alt="Silver Earrings" />
                        <h3>Earrings</h3>
                        <p>
                            A stunning collection of silver earrings that add a touch of elegance to any look.
                        </p>
                        <button className="view-product-btn" onClick={() => openModal('Silver Earrings')}>
                            View Product
                        </button>
                    </div>

                </div>
            </div>

            {/* Modal Section */}
            {isModalOpen && (
                <ProductModal
                    productName={modalProduct.productName} // Passing product name to modal
                    productImages={modalProduct.images}
                    productNames={modalProduct.names}
                    productDescription={modalProduct.description}
                    closeModal={closeModal}
                />
            )}
            <div className="silver-cards-section">

                <div className="silver-cards-container">
                    <div className="silver-cards-card">
                        <img src={silverBraceletImage} alt="Silver Jewelleries" />
                        <h3>Bracelet</h3>
                    </div>
                    <div className="silver-cards-card">
                        <img src={silverBrauchesImage} alt="Precious Stones" />
                        <h3>Brauches</h3>
                    </div>
                    <div className="silver-cards-card">
                        <img src={silverCufflinksImage} alt="Semi Precious Stones" />
                        <h3>Cufflinks</h3>
                    </div>
                    <div className="silver-cards-card">
                        <img src={silverTiepinsImage} alt="Semi Precious Stones" />
                        <h3>Tie Pins</h3>
                    </div>
                    <div className="silver-cards-card">
                        <img src={silverChainsImage} alt="Semi Precious Stones" />
                        <h3>Chains</h3>
                    </div>
                </div>
            </div>
            <div className="other-products-section">
                <h2>Other Products</h2>
                <div className="other-products-container">
                    <a href="/gold-jewellery" className="other-product-card">
                        <img src={goldJewelleriesImage} alt="Silver Jewelleries" />
                        <h3>Gold Jewelleries</h3>
                    </a>
                    <a href="/precious-stones" className="other-product-card">
                        <img src={preciousStonesImage} alt="Precious Stones" />
                        <h3>Precious Stones</h3>
                    </a>
                    <a href="/semi-precious-stones" className="other-product-card">
                        <img src={semiPreciousStonesImage} alt="Semi Precious Stones" />
                        <h3>Semi Precious Stones</h3>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SilverJewelleries;
