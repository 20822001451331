import React, { useState } from "react";
import { FaGem, FaRegGem, FaRing } from "react-icons/fa";
import { FaDiamond } from "react-icons/fa6";
import { GiGemNecklace, GiPearlNecklace, GiHeartEarrings, GiWavyChains, GiGemChain, GiLockSpy, GiAndromedaChain } from "react-icons/gi";
import { LiaCircleSolid } from "react-icons/lia";
import { SiBlockchaindotcom, SiHiveBlockchain } from "react-icons/si";
import { IoIosMenu } from "react-icons/io";


import goldJewelleryImage from '../assets/ourproducts/gatsby-jewellery-the-history-of-gold-jewellery_1254x.webp';
import silverJewelleryImage from '../assets/ourproducts/il_570xN.3984822511_bl49.webp';
import preciousstonesImage from '../assets/ourproducts/openart-image_8c1DxHQv_1718001321218_raw.webp';
import semipreciousstonesImage from '../assets/ourproducts/Semi-Precious-Stones-Guide.png';

import Footer from "../components/Footer";

import "./OurProducts.css"; // Import the CSS for styling

const OurProducts = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState(null); // Track which category is active

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleCategoryClick = (category) => {
        if (activeCategory === category) {
            setActiveCategory(null); // Close the category if it's already open
        } else {
            setActiveCategory(category); // Open the clicked category
        }
    };

    const renderSubmenu = (category) => {
        switch (category) {
            case "goldJewellery":
                return (
                    <ul className="sub-menu">
                        <li><FaRing /> Rings</li>
                        <li><GiGemNecklace /> Pendants</li>
                        <li><GiPearlNecklace /> Necklaces</li>
                        <li><GiHeartEarrings /> Ear Rings</li>
                        <li><LiaCircleSolid /> Bracelets</li>
                        <li><GiGemChain /> Chains</li>
                        <li><GiLockSpy /> Locks</li>
                        <li><GiAndromedaChain /> Sets</li>
                    </ul>
                );
            case "silverJewellery":
                return (
                    <ul className="sub-menu">
                        <li><GiGemNecklace /> Pendants</li>
                        <li><GiPearlNecklace /> Necklaces</li>
                        <li><GiHeartEarrings /> Ear Rings</li>
                        <li><LiaCircleSolid /> Bracelets/Bangles</li>
                        <li><GiWavyChains /> Broaches</li>
                        <li><SiBlockchaindotcom /> Cufflinks, Tie Pins</li>
                        <li><FaRegGem /> Piercings</li>
                        <li><GiGemChain /> Chains</li>
                        <li><GiAndromedaChain /> Sets</li>
                        <li><SiHiveBlockchain /> Fashion Jewellery</li>
                    </ul>
                );
            case "preciousStones":
                return (
                    <ul className="sub-menu">
                        <li><FaDiamond /> Sapphire</li>
                        <li><FaDiamond /> Ruby</li>
                        <li><FaDiamond /> Cat's Eye</li>
                        <li><FaDiamond /> Star Stones</li>
                        <li><FaDiamond /> Emerald & Alexandrite</li>
                        <li><FaDiamond /> Diamonds</li>
                    </ul>
                );
            case "semiPreciousStones":
                return (
                    <ul className="sub-menu">
                        <li><FaGem /> Moonstone</li>
                        <li><FaGem /> Topaz</li>
                        <li><FaGem /> Amethyst</li>
                        <li><FaGem /> Garnet</li>
                        <li><FaGem /> Tourmaline</li>
                        <li><FaGem /> Spinel</li>
                        <li><FaGem /> Peridot</li>
                        <li><FaGem /> Diopside</li>
                        <li><FaGem /> Citrin</li>
                        <li><FaGem /> Quartz</li>
                        <li><FaGem /> Opal</li>
                    </ul>
                );
            default:
                return null;
        }
    };

    return (
        <div className="our-products-container">
            {/* Navbar */}
            <nav className="productsbar">
                <div className="productsbar-left">
                    <button className="menu-button" onClick={toggleMenu}>
                        <span className="menu-icon"><IoIosMenu /></span> Menu
                    </button>
                </div>


            </nav>

            {/* Main Side Menu */}
            {isMenuOpen && (
                <div className="side-menu-container">
                    <div className="side-menu">
                        {/* Close Button for Main Menu */}
                        <button className="close-button" onClick={toggleMenu}>
                            &times; Close
                        </button>
                        <ul className="menu-list">
                            <li
                                className={activeCategory === 'goldJewellery' ? 'active' : ''}
                                onClick={() => handleCategoryClick('goldJewellery')}
                            >
                                <FaRegGem /> Gold Jewelleries
                                {activeCategory === 'goldJewellery' && <span className="arrow"> &gt;</span>}
                            </li>
                            <li
                                className={activeCategory === 'silverJewellery' ? 'active' : ''}
                                onClick={() => handleCategoryClick('silverJewellery')}
                            >
                                <FaRegGem /> Silver Jewelleries
                                {activeCategory === 'silverJewellery' && <span className="arrow"> &gt;</span>}
                            </li>
                            <li
                                className={activeCategory === 'preciousStones' ? 'active' : ''}
                                onClick={() => handleCategoryClick('preciousStones')}
                            >
                                <FaRegGem /> Precious Stones
                                {activeCategory === 'preciousStones' && <span className="arrow"> &gt;</span>}
                            </li>
                            <li
                                className={activeCategory === 'semiPreciousStones' ? 'active' : ''}
                                onClick={() => handleCategoryClick('semiPreciousStones')}
                            >
                                <FaRegGem /> Semi Precious Stones
                                {activeCategory === 'semiPreciousStones' && <span className="arrow"> &gt;</span>}
                            </li>
                        </ul>
                    </div>

                    {/* Vertical Divider - Displayed Only When a Category is Active */}
                    {activeCategory && <div className="divider"></div>}

                    {/* Right Sidebar for Submenu */}
                    {activeCategory && (
                        <div className="right-side-menu">
                            <h3>{activeCategory}</h3>
                            {renderSubmenu(activeCategory)}
                        </div>
                    )}
                </div>
            )}

            {/* Overlay */}
            {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
            <section className="gold-jewellery-section">
                <div className="gold-jewellery-left">
                    <h2>GOLD JEWELLERIES</h2>
                    <p>WE OFFER IN YELLOW AND WHITE GOLD IN 18K, 14K.</p>
                    <a href="/gold-jewellery" className="view-more-button">View More</a>
                </div>
                <div className="gold-jewellery-right">
                    <img src={goldJewelleryImage} alt="Gold Jewellery" className="gold-jewellery-image" />
                </div>
            </section>
            <section className="silver-jewellery-section">
                <div className="silver-jewellery-left">
                    <h2>SILVER JEWELLERIES</h2>
                    <p>Elegant sterling silver jewelry for every occasion.</p>
                    <a href="/silver-jewellery" className="view-more-button-2">View More</a>
                </div>
                <div className="silver-jewellery-right">
                    <img src={silverJewelleryImage} alt="Silver Jewellery" className="silver-jewellery-image" />
                </div>
            </section>
            <section className="precious-stones-section">
                <div className="precious-stones-left">
                    <h2>PRECIOUS STONES</h2>
                    <p>Exquisite precious stones: radiant rubies, dazzling diamonds, elegant emeralds, and stunning sapphires.</p>
                    <a href="/precious-stones" className="view-more-button-3">View More</a>
                </div>
                <div className="precious-stones-right">
                    <img src={preciousstonesImage} alt="Precious Jewellery" className="precious-stones-image" />
                </div>
            </section>
            <section className="semi-precious-stones-section">
                <div className="semi-precious-stones-left">
                    <h2>SEMI PRECIOUS STONES</h2>
                    <p>Vibrant semi-precious stones, perfect for unique elegance.</p>
                    <a href="/semi-precious-stones" className="view-more-button-4">View More</a>
                </div>
                <div className="semi-precious-stones-right">
                    <img src={semipreciousstonesImage} alt="semi precious Jewellery" className="semi-precious-stones-image" />
                </div>
            </section>
            <Footer />
        </div>
    );
};



export default OurProducts;
