import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Masonry from 'react-masonry-css';
import "slick-carousel/slick/slick.css"; // Import Slick Carousel CSS
import "slick-carousel/slick/slick-theme.css";
import { Fade } from "react-awesome-reveal";
import image1 from "../assets/Home/DALL·E 2024-12-09 21.17.12 - A luxurious close-up of various colored gemstones, including sapphires, rubies, and emeralds, arranged elegantly on a velvet background, with a soft g.webp";
import image2 from "../assets/Home/DALL·E 2024-12-09 21.17.42 - A lavish arrangement of gold jewelry, including necklaces, bracelets, and rings, artfully placed on a dark marble surface with soft lighting creating .webp";
import image3 from "../assets/Home/DALL·E 2024-12-09 21.18.11 - An elegant display of sparkling diamond rings and necklaces, set on a glossy white surface with soft lighting that reflects off the jewels, creating a.webp";
import image4 from "../assets/Home/DALL·E 2024-12-09 21.24.57 - A luxurious background image for a gemstone jewelry website featuring a collection of sparkling gemstones such as diamonds, sapphires, rubies, and eme.webp";
import image5 from "../assets/Home/DALL·E 2024-12-09 21.20.12 - An elegant display of sparkling diamond rings and necklaces, set on a glossy black surface with soft lighting that reflects off the jewels, creating a.webp";
import welcomeImage from "../assets/Home/464721965_8809782655749949_6188245611038689600_n.jpg";
import gemstone1 from "../assets/Home/78111436_2731260300268912_1095133509913149440_n.jpg";
import gemstone2 from "../assets/Home/52681125_2171396462921968_8230024394248814592_n.png";
import gemstone3 from "../assets/Gold Jewelleries/67180744_2426044177457194_1920192930723659776_n (1).jpg";
import gemstone4 from "../assets/Home/DALL_E_2024-03-15_14.04.56_-_Create_a_highly_realistic_photo_of_just_a_few_cut_and_polished_royal_blue_sapphires_presented_in_a_minimalistic_style_on_a_plain_background._The_sapp_1000x1000_crop_center.webp";
import gemstone5 from "../assets/Home/d.webp";
import gemstone6 from "../assets/Home/images.jpeg";

import collectionImage1 from "../assets/Home/Image collection/80285193_2731268860268056_382139243373264896_n.jpg";
import collectionImage2 from "../assets/Home/Image collection/405953777_18005804258504638_147169756377035333_n.jpg";
import collectionImage3 from "../assets/Home/Image collection/406222256_18005930042504638_491877479047434524_n.jpg";
import collectionImage4 from "../assets/Home/Image collection/347463519_282627180785338_6122604589742269811_n.webp";
import collectionImage5 from "../assets/Home/Image collection/154434139_437199100720983_8330922191768408100_n 2.jpg";
import collectionImage6 from "../assets/Home/Image collection/332419802_1293833707840657_7108864790149828785_n.webp";
import collectionImage7 from "../assets/Home/Image collection/55837833_2234308113297469_976440911867150336_n.jpg";
import collectionImage8 from "../assets/Home/Image collection/311500664_199574425843778_8225488658124807266_n.webp";
import collectionImage9 from "../assets/Home/Image collection/371476830_17993867630504638_2645444577412317236_n.jpg";
import collectionImage10 from "../assets/Home/Image collection/79495479_2731265046935104_8064118380359057408_n.jpg";
import collectionImage11 from "../assets/Home/Image collection/41530390_1950831701645113_2970872103194066944_n.jpg";


import { FaCertificate, FaMicroscope, FaShippingFast, FaGem, FaSmile, FaShieldAlt } from "react-icons/fa";
import "./Home.css";
import Footer from "../components/Footer";
// Slide properties for the slideshow
const slideImages = [image1, image2, image3, image4, image5];

const slideProperties = {
    duration: 5000, // Display each image for 5 seconds
    transitionDuration: 500, // Transition duration between images
    infinite: true, // Loop the slideshow
    indicators: true, // Show navigation indicators
    arrows: false, // Hide navigation arrows
    autoplay: true, // Enable automatic transitions
    pauseOnHover: true, // Pause on hover
};


// Gemstone Card Component
const GemstoneCard = ({ image, title, exploreLink }) => {
    return (
        <div className="gemstone-card">
            <img src={image} alt={title} className="gemstone-image" />
            <div className="gemstone-info">
                <h3>{title}</h3>

                <div className="gemstone-explore">
                    <a href={exploreLink} target="_blank" rel="noopener noreferrer">
                        <button >Explore</button>
                    </a>
                </div>
            </div>
        </div>
    );
};
// Elfsight Google Reviews Widget Component
const ElfsightReviews = () => {
    const [totalReviews, setTotalReviews] = useState(null);

    useEffect(() => {
        // Load Elfsight platform.js script dynamically
        const script = document.createElement("script");
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.async = true;
        document.body.appendChild(script);

        // Fetch total number of reviews from Google Places API
        const fetchTotalReviews = async () => {
            const placeId = 'ChIJYz0eXGYu4joRBgm5poh9fN8'; // Your Place ID
            const apiKey = 'YOUR_GOOGLE_API_KEY'; // Replace with your API key

            try {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${apiKey}`
                );
                const data = await response.json();
                if (data.result && data.result.user_ratings_total) {
                    setTotalReviews(data.result.user_ratings_total);
                } else {
                    console.error('Failed to fetch total reviews.');
                }
            } catch (error) {
                console.error('Error fetching total reviews:', error);
            }
        };

        fetchTotalReviews();

        return () => {
            document.body.removeChild(script); // Cleanup script when component unmounts
        };
    }, []);

    return (
        <div className="reviews-widget">
            <h3>Total Reviews: {totalReviews !== null ? totalReviews : 'Loading...'}</h3>
            <div className="elfsight-app-cdbb79ac-33b9-4e3e-a2bd-4f684aeddcae" data-elfsight-app-lazy></div>
        </div>
    );
};

// Elfsight Tripadvisor Reviews Widget Component
const TripadvisorReviews = () => {
    useEffect(() => {
        // Load Elfsight platform.js script dynamically
        const script = document.createElement("script");
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Cleanup script when component unmounts
        };
    }, []);

    return (
        <div className="tripadvisor-widget">
            <div className="elfsight-app-5dbd4b21-0263-4ffd-a433-24cc6d2a1811" data-elfsight-app-lazy></div>
        </div>
    );
};
const images = [collectionImage1, collectionImage2, collectionImage3, collectionImage4, collectionImage5, collectionImage6, collectionImage7, collectionImage8, collectionImage9, collectionImage10, collectionImage11];

const ImageGallery = () => {
    const breakpointColumnsObj = {
        default: 3, // Default 3 columns
        1100: 3,    // 3 columns for screens > 1100px
        700: 2,     // 2 columns for screens > 700px
        500: 1      // 1 column for screens <= 500px
    };

    // Custom styles for individual images
    const customStyles = [
        { width: "500px", height: "350px" },
        { width: "100%", height: "250px" },
        { width: "100%", height: "400px" },
        { width: "100%", height: "350px" },
        { width: "100%", height: "200px" },
        { width: "100%", height: "500px" },
        { width: "100%", height: "280px" },
        { width: "500px", height: "550px" },
        { width: "100%", height: "400px" },
        { width: "100%", height: "400px" },
        { width: "100%", height: "400px" }
    ];

    return (
        <div className="gallery-section">

            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="gallery-grid"
                columnClassName="gallery-column"
            >
                {images.map((image, index) => (
                    <Fade key={index} direction="up" duration={1000} triggerOnce>
                        <div className="gallery-item">
                            <img
                                src={image}
                                alt={`Gallery item ${index + 1}`}
                                style={customStyles[index % customStyles.length]}
                            />
                        </div>
                    </Fade>
                ))}
            </Masonry>
        </div>
    );
};

const WhyChooseUs = () => {
    const features = [
        {
            icon: <FaCertificate />,
            title: "43 YEARS OF TRUST",
            description: "100% Purity Guaranteed",
        },
        {
            icon: <FaMicroscope />,
            title: "CERTIFIED GEMSTONES",
            description: "Untreated And Unheated",
        },
        {
            icon: <FaShippingFast />,
            title: "WORLDWIDE SHIPPING",
            description: "Top Sri Lankan Exporters",
        },
        {
            icon: <FaGem />,
            title: "DIRECT FROM MINES",
            description: "To Our Customers",
        },
        {
            icon: <FaSmile />,
            title: "HAPPY & SATISFIED",
            description: "Customer Reviews",
        },
        {
            icon: <FaShieldAlt />,
            title: "SAFE AND SECURED",
            description: "Courier Tracking",
        },
    ];

    return (
        <div className="why-choose-us">
            <h2>WHY CHOOSE US</h2>
            <div className="features-container">
                {features.map((feature, index) => (
                    <div className="feature-item" key={index}>
                        <div className="feature-icon">{feature.icon}</div>
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};
// Home Component
const Home = () => {


    const [currentSlide, setCurrentSlide] = useState(0);
    const gemstoneImages = [
        { image: gemstone1, title: "Necklaces", exploreLink: "/gold-jewellery" },
        { image: gemstone2, title: "Rings", exploreLink: "/silver-jewellery" },
        { image: gemstone3, title: "Bracelets", exploreLink: "/gold-jewellery" },
        { image: gemstone4, title: "Blue Sapphire ", exploreLink: "/precious-stones" },
        { image: gemstone5, title: "Topaz", exploreLink: "/precious-stones" },
        { image: gemstone6, title: "Garnet", exploreLink: "/semi-precious-stones" }
    ];

    const handleLeftArrowClick = () => {
        const newSlide = currentSlide === 0 ? gemstoneImages.length - 3 : currentSlide - 3;
        setCurrentSlide(newSlide);
    };

    const handleRightArrowClick = () => {
        const newSlide = currentSlide === gemstoneImages.length - 3 ? 0 : currentSlide + 3;
        setCurrentSlide(newSlide);
    };

    const gemstoneCards = gemstoneImages.slice(currentSlide, currentSlide + 3).map((gemstone, index) => (
        <GemstoneCard key={index} image={gemstone.image} title={gemstone.title} description={gemstone.description} exploreLink={gemstone.exploreLink} />
    ));

    useEffect(() => {
        // Select the welcome section and its child elements
        const welcomeSection = document.querySelector('.welcome-section');
        const welcomeContent = document.querySelector('.welcome-content');
        const welcomeImage = document.querySelector('.welcome-image');

        if (welcomeSection) {
            // Initialize the Intersection Observer
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        // Add animation classes when the section is in view
                        entry.target.classList.add('show');
                        welcomeContent.classList.add('show-content');
                        welcomeImage.classList.add('show-image');
                        observer.unobserve(entry.target); // Stop observing once the animation is triggered
                    }
                });
            }, { threshold: 0.5 }); // Trigger when 50% of the section is in view

            // Observe the welcome section
            observer.observe(welcomeSection);

            // Clean up the observer when the component is unmounted
            return () => {
                observer.disconnect();
            };
        }
    }, []);

    return (
        <div className="home-container">
            <Slide {...slideProperties}>
                {slideImages.map((image, index) => (
                    <div className="each-slide" key={index}>
                        <img src={image} alt={`Slide ${index + 1}`} className="slide-image" />
                        <div className="slide-overlay">
                            <h2 className="slide-heading">Luxury through rarity created with passion</h2>
                        </div>
                    </div>
                ))}
            </Slide>
            {/* Welcome Section */}
            <div className="welcome-section">
                <div className="welcome-content">
                    <h1>Welcome to Dara Gems</h1>
                    <p>
                        Welcome to Dara Gems, where timeless elegance meets exquisite craftsmanship. Our collection showcases a stunning array of finely crafted jewellery, designed to capture the beauty of nature’s finest gems. From radiant necklaces and intricate rings to delicate earrings and bracelets, each piece tells a unique story of luxury and sophistication. With over 40 years of expertise in the gem and jewellery industry, we take pride in offering our clients only the finest, ensuring that every purchase is a treasure that will last a lifetime. Explore our showcase and discover the brilliance of Dara Gems!
                    </p>
                    <p>At Dara Gems, we specialize in creating jewellery that embodies elegance and style. Our curated collection features beautifully crafted pieces, designed to complement every occasion. With a commitment to quality and precision, we bring you the best in gem-studded accessories, ensuring a perfect blend of tradition and modern trends. Visit us and let us help you find the perfect gem to shine in your life.</p>
                </div>
                <div className="welcome-image">
                    <img src={welcomeImage} alt="Welcome" />
                </div>
            </div>
            {/* Gemstone Collection Section */}
            <div className="gemstone-collection">
                <h2>Our Collections</h2>
                <div className="gemstone-cards">
                    {gemstoneCards}
                </div>
                <Link to="/our-products">
                    <button className="explore-all">Explore All</button>
                </Link>
                <div className="arrow-buttons">
                    <button className="left-button" onClick={handleLeftArrowClick}>&#8592;</button>
                    <button className="right-button" onClick={handleRightArrowClick}>&#8594;</button>
                </div>
            </div>
            <div className="reviews-section">

                <ElfsightReviews />
            </div>
            <div className="reviews-section">

                <TripadvisorReviews />
            </div>
            <ImageGallery />
            <WhyChooseUs />
            <Footer />
        </div>
    );
};

export default Home;
