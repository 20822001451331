// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC7ipULsv_tnd0l2LPubXBvr-eD_rznL4M",
    authDomain: "daragems-dc04d.firebaseapp.com",
    projectId: "daragems-dc04d",
    storageBucket: "daragems-dc04d.firebasestorage.app",
    messagingSenderId: "534238444714",
    appId: "1:534238444714:web:033a747c2da1b48d4f24d3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };