// GoldJewelleries.jsx
import React, { useState } from "react";


import goldRingsImage from "../assets/Gold Jewelleries/289186189_520978603154975_9032543253791922075_n.jpg";
import goldPendantsImage from "../assets/Gold Jewelleries/9255IST-1.jpg";
import goldNecklacesImage from "../assets/Gold Jewelleries/1.png";
import goldEarringsImage from "../assets/Gold Jewelleries/IMG_5309.webp";
import goldBraceletsImage from "../assets/Gold Jewelleries/Untitled design1.png";
import goldChainsImage from "../assets/Gold Jewelleries/41K1NxQPe4L._SS400_.jpg";



import goldRingImage1 from "../assets/Gold Jewelleries/60996281_2311257532269193_3846495057288888320_n.png";
import goldRingImage2 from "../assets/Gold Jewelleries/289448659_520978569821645_5054907169752357243_n.jpg";
import goldRingImage3 from "../assets/Gold Jewelleries/52075974_2161573020570979_9126368108825542656_n.png";
import goldPendantsImage1 from "../assets/Gold Jewelleries/images.jpeg";
import goldPendantsImage2 from "../assets/Gold Jewelleries/54D272CA-18896E78A54-1637-5547-817-8203000001.jpg";
import goldPendantsImage3 from "../assets/Gold Jewelleries/WhatsApp-Image-2024-04-09-at-2.50.03-PM-1.jpeg";
import goldNecklacesImage1 from "../assets/Gold Jewelleries/80076579_2731642753564000_4827307893676048384_n.jpg";
import goldNecklacesImage2 from "../assets/Gold Jewelleries/79480485_2731264063601869_1622713647442690048_n.jpg";
import goldNecklacesImage3 from "../assets/Gold Jewelleries/465050538_8838094709585410_2578170286491028115_n.jpg";
import goldEarringsImage1 from "../assets/Gold Jewelleries/IMG_6412.webp";
import goldEarringsImage2 from "../assets/Gold Jewelleries/images (1).jpeg";
import goldBraceletsImage1 from "../assets/Gold Jewelleries/67180744_2426044177457194_1920192930723659776_n (1).jpg";
import goldBraceletsImage2 from "../assets/Gold Jewelleries/Ladies-Bracelets-500x668-1.jpg";
import goldChainsImage1 from "../assets/Gold Jewelleries/61c1gNV8qVL.jpg";
import goldChainsImage2 from "../assets/Gold Jewelleries/65058096201e005c4a3a471f-gold-chain-24-gold-necklace-fake-gold.jpg";

import silverJewelleriesImage from "../assets/Gold Jewelleries/80393529_2731643716897237_8539846507300388864_n.jpg";
import preciousStonesImage from "../assets/Gold Jewelleries/istockphoto-1067431158-612x612.jpg";
import semiPreciousStonesImage from "../assets/Gold Jewelleries/IMG_5342_1200x800.webp";

import "./GoldJewelleries.css";
import ProductModal from "./ProductModal";
import Footer from "../components/Footer";

const GoldJewelleries = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProduct, setModalProduct] = useState({
        images: [],
        names: [],
        description: '',
        productName: '', // New state for product name
    });

    const openModal = (productName) => {
        let productImages = [];
        let productNames = [];
        let productDescription = '';

        switch (productName) {
            case 'Gold Rings':
                productImages = [goldRingImage1, goldRingImage2, goldRingImage3];

                productDescription = 'Our collection of gold rings features timeless designs, perfect for engagement, wedding, or special occasions.';
                break;
            case 'Gold Pendants':
                productImages = [goldPendantsImage1, goldPendantsImage2, goldPendantsImage3];

                productDescription = 'Gold pendants that add elegance and sophistication to any outfit.';
                break;
            case 'Gold Necklaces':
                productImages = [goldNecklacesImage1, goldNecklacesImage2, goldNecklacesImage3];

                productDescription = 'Gold necklaces that elevate your style and grace your neck.';
                break;

            case 'Gold Earrings':
                productImages = [goldEarringsImage1, goldEarringsImage2,];

                productDescription = 'Gold necklaces that elevate your style and grace your neck.';
                break;

            case 'Gold Bracelets':
                productImages = [goldBraceletsImage1, goldBraceletsImage2];

                productDescription = 'Gold necklaces that elevate your style and grace your neck.';
                break;

            case 'Gold Chains':
                productImages = [goldChainsImage1, goldChainsImage2];

                productDescription = 'Gold necklaces that elevate your style and grace your neck.';
                break;
            default:
                break;
        }

        setModalProduct({
            images: productImages,
            names: productNames,
            description: productDescription,
            productName: productName, // Pass the product name
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    return (
        <div className="gold-jewelleries-container">


            <div className="new-gold-items-section">
                <h2>Gold Items</h2>
                <div className="new-image-cards-container">
                    {/* Gold Rings Card */}
                    <div className="img-card">
                        <img src={goldRingsImage} alt="Gold Rings" />
                        <h3>Rings</h3>
                        <p>
                            Our collection of gold rings features timeless designs, perfect for engagement, wedding, or special occasions.
                        </p>
                        <button className="view-product-btn" onClick={() => openModal('Gold Rings')}>
                            View Product
                        </button>
                    </div>

                    {/* Gold Pendants Card */}
                    <div className="img-card">
                        <img src={goldPendantsImage} alt="Gold Pendants" />
                        <h3>Pendants</h3>
                        <p>
                            Gold pendants that add elegance and sophistication to any outfit.
                        </p>
                        <button className="view-product-btn" onClick={() => openModal('Gold Pendants')}>
                            View Product
                        </button>
                    </div>

                    {/* Gold Necklaces Card */}
                    <div className="img-card">
                        <img src={goldNecklacesImage} alt="Gold Necklaces" />
                        <h3>Necklaces</h3>
                        <p>
                            Handcrafted gold necklaces perfect for gifting or as a personal accessory.
                        </p>
                        <button className="view-product-btn" onClick={() => openModal('Gold Necklaces')}>
                            View Product
                        </button>
                    </div>

                    {/* Gold Earrings Card */}
                    <div className="img-card">
                        <img src={goldEarringsImage} alt="Gold Earrings" />
                        <h3>Earrings</h3>
                        <p>
                            A stunning collection of gold earrings that add a touch of elegance to any look.
                        </p>
                        <button className="view-product-btn" onClick={() => openModal('Gold Earrings')}>
                            View Product
                        </button>
                    </div>

                    {/* Gold Bracelets Card */}
                    <div className="img-card">
                        <img src={goldBraceletsImage} alt="Gold Bracelets" />
                        <h3>Bracelets</h3>
                        <p>
                            Elegant gold bracelets crafted to fit any wrist.
                        </p>
                        <button className="view-product-btn" onClick={() => openModal('Gold Bracelets')}>
                            View Product
                        </button>
                    </div>

                    {/* Gold Chains Card */}
                    <div className="img-card">
                        <img src={goldChainsImage} alt="Gold Chains" />
                        <h3>Chains</h3>
                        <p>
                            Discover our collection of gold chains, perfect for pairing with pendants or wearing solo.
                        </p>
                        <button className="view-product-btn" onClick={() => openModal('Gold Chains')}>
                            View Product
                        </button>
                    </div>



                </div>
            </div>

            {/* Modal Section */}
            {isModalOpen && (
                <ProductModal
                    productName={modalProduct.productName} // Passing product name to modal
                    productImages={modalProduct.images}
                    productNames={modalProduct.names}
                    productDescription={modalProduct.description}
                    closeModal={closeModal}
                />
            )}
            <div className="other-products-section">
                <h2>Other Products</h2>
                <div className="other-products-container">
                    <a href="/silver-jewellery" className="other-product-card">
                        <img src={silverJewelleriesImage} alt="Silver Jewelleries" />
                        <h3>Silver Jewelleries</h3>
                    </a>
                    <a href="/precious-stones" className="other-product-card">
                        <img src={preciousStonesImage} alt="Precious Stones" />
                        <h3>Precious Stones</h3>
                    </a>
                    <a href="/semi-precious-stones" className="other-product-card">
                        <img src={semiPreciousStonesImage} alt="Semi Precious Stones" />
                        <h3>Semi Precious Stones</h3>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default GoldJewelleries;
